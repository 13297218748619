import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { getResourceList } from '../../models/BaseModel'
import { ImageReplacementAnchor, FooterNavigation, SocialLinks, BlankErrorBoundary } from '@scrippsproduct/scripps-external-component-library'

import './../../scss/Footer.scss'
import gritLogo from './../../images/grit-logo.png'

export default function Footer() {
    const [navSections, setNavSections] = useState([])
    const [socialLinks, setSocialLinks] = useState([])
    const {isSuccess, data} = useQuery(['footer', {resourceName: 'footer'}], getResourceList)

    useEffect(() => {
        if (isSuccess) {
            setNavSections(data.get('navSections'))
            setSocialLinks(data.get('socialLinks'))
        }
    }, [isSuccess, data])

    return (
        <footer className="main-footer">
            <content-constrainer>
                <div className="footer-logo">
                    <ImageReplacementAnchor href={'/'} 
                        image={{
                            url: gritLogo, 
                            width: 270, 
                            height: 100
                        }} 
                        height={3.4}
                        baselineAdjustment={-1}
                    />
                </div>
                <div className="footer-nav-wrapper">
                    { (isSuccess) && 
                        <BlankErrorBoundary>
                            <FooterNavigation sections={navSections} />
                        </BlankErrorBoundary>
                    }
                    { (isSuccess && socialLinks.length > 0) &&
                        <BlankErrorBoundary>
                            <SocialLinks size={1.5} showHeader={true} platforms={socialLinks}/>
                        </BlankErrorBoundary> 
                    }
                </div>
                <div className="copyright-notice">
                    {`© ${new Date().getFullYear()} Grit Media, LLC, part of The E.W. Scripps Company. All Rights Reserved.`}
                </div>
            </content-constrainer>
        </footer>
    )
}
