import React from 'react'
import SEO from './../utils/SEOMetaTags'

import './../scss/PageNotFound.scss'

export default function PageNotFound({ message = null, page = {name: 'home', path: '/'} }) {
    return (
        <div className="page-not-found">
            <SEO 
                title="Page Not Found - Grit"
                description="The url could not be found"
                robots="noindex"
            />
            <section className="page-not-found__section">                
                <h1 className='h2 page-not-found__header'>Sorry, this page isn't available</h1>
                <div className="page-not-found__message">
                    { (message === null) && <>If you followed a link it may be broken, or the page may have been removed.</> }
                    { (message !== null) && <>{message}</>}
                </div>
                <div className="page-not-found__link">
                    Go to the <a href={page.path}> {page.name} page.</a>
                </div>
                { (page.name !== 'home') && 
                    <div className='page-not-found__link'>
                        Go to Grit's <a href="/"> home page.</a>
                    </div> 
                }
            </section>
            <section className="page-not-found__section">
                <h2 className="h6">About Grit</h2>
                <p>
                    {/* TODO: add a description of Grit here. */}
                    Go to our <a href="/find-us">Find Us</a> page to find out where you can watch Grit. 
                </p>
            </section>
        </div>
    )
}
