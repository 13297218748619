import {request, gql} from 'graphql-request'
import { snakeToPascal } from '@scrippsproduct/scripps-external-component-library/dist/utils/helpers'
import { transformShowSlideLayout, transformImageCardLayout, transformTwoColumnSlideLayout, transformPromotion } from './SlideTransformers';

const ENDPOINT = process.env.REACT_APP_BS_API_ENDPOINT
const SITE_ID = process.env.REACT_APP_SITE_ID
const BS_API_TOKEN = process.env.REACT_APP_BS_TOKEN

const showsToSkip = ['ANS', 'SMX', 'XAP', 'WBA', 'BWF']

const carouselSlideTransformer = ({carousel, slide, index}) => {
  let transformedSlide;
  // PROMO carousels ignore the layout selection since they use the PromotionsRowModule
  if (carousel.type === 'PROMO') {
      return transformPromotion(slide);
  } else {
      const layout = snakeToPascal(slide.layout);
      // Use slide's layout to determine how the data should be transformed
      switch (layout) {
          case 'ShowSlide':
              transformedSlide = transformShowSlideLayout(slide, index);
              break;
          case 'MovieSlide':
              transformedSlide = transformShowSlideLayout(slide, index, 'film')
              break
          case 'ImageCard':
              transformedSlide = transformImageCardLayout(slide, carousel.type);
              break;
          case 'TwoColumnSlide':
              transformedSlide = transformTwoColumnSlideLayout(slide);
              break;
          default:
              transformedSlide = {
                layout: layout,
                props: {},
              };
              break;
      }

      return transformedSlide
  }
}

const carouselTransformer = ({carousel}) => {
  return {
    type: carousel.type,
    carouselName: carousel.name,
    slides: carousel.slides.map((slide, index) => {
      return carouselSlideTransformer({carousel, slide, index})
    })
  }
}

const Transformers = new Map([
    ['showspage', data => data.SeriesList.items.filter(item => !showsToSkip.includes(item.seriesPrefix)).map(item => {
        return {
            id: item.id,
            image: {
                url: item.featuredImage?.image?.publicUrl,
                altText: item.title,
                aspectRatio: 0.677530017152659,
                sources: [
                  {
                    media: 'min-width: 1530px',
                    srcsets: [
                      `${item.featuredImage?.image?.sizes?.[0]?.srcSets?.[1]?.src} 1x`,
                      `${item.featuredImage?.image?.sizes?.[0]?.srcSets?.[3]?.src} 2x`,
                    ],
                  },
                  {
                    media: 'min-width: 860px',
                    srcsets: [
                      `${item.featuredImage?.image?.sizes?.[0]?.srcSets?.[0]?.src} 1x`,
                      `${item.featuredImage?.image?.sizes?.[0]?.srcSets?.[1]?.src} 2x`,
                    ],
                  },
                  {
                    media: 'min-width: 800px',
                    srcsets: [
                      `${item.featuredImage?.image?.sizes?.[0]?.srcSets?.[1]?.src} 1x`,
                      `${item.featuredImage?.image?.sizes?.[0]?.srcSets?.[3]?.src} 2x`,
                    ],
                  },
                  {
                    media: 'min-width: 600px',
                    srcsets: [
                      `${item.featuredImage?.image?.sizes?.[0]?.srcSets?.[0]?.src} 1x`,
                      `${item.featuredImage?.image?.sizes?.[0]?.srcSets?.[1]?.src} 2x`,
                    ],
                  },
                  {
                    media: 'min-width: 500px',
                    srcsets: [
                      `${item.featuredImage?.image?.sizes?.[0]?.srcSets?.[1]?.src} 1x`,
                      `${item.featuredImage?.image?.sizes?.[0]?.srcSets?.[3]?.src} 2x`,
                    ],
                  },
                  {
                    media: 'min-width: 1px',
                    srcsets: [
                      `${item.featuredImage?.image?.sizes?.[0]?.srcSets?.[0]?.src} 1x`,
                      `${item.featuredImage?.image?.sizes?.[0]?.srcSets?.[1]?.src} 2x`,
                    ],
                  },
                ]
            },
            content: '<span class="no-airdate">&nbsp;</span>',
            cta: (item.preventDetailPage) ? {
              href: '',
              type: 'unknown',
            } : {
                href: `show/${item.slug}`,
                type: 'internal-link',
            },
            seriesPrefix: item.seriesPrefix,
        }
    })],
    ['showDetails', data =>( 
        {
            SeriesList: data.SeriesList.items.map(item => {
                return {
                    id: item.id,
                    slug: item.slug,
                    seriesPrefix: item.seriesPrefix,
                    title: item.title,
                    series_synopsis: item.description,
                    featuredImage: {
                      url: item.featuredImage?.image?.publicUrl,
                      altText: item.title,
                      aspectRatio: item.featuredImage?.image?.width/item.featuredImage?.image?.height,
                    },
                    detailImage: {
                      url: item.detailImage?.image?.publicUrl,
                      altText: item.title,
                      aspectRatio: item.detailImage?.image?.width/item.detailImage?.image?.height,
                    },
                    genres: item.genre ?? '',
                    primary_color: item?.colors?.entries[0]?.value ?? '',
                    secondary_color: item?.colors?.entries[1]?.value ?? '',
                    rating: item.contentRating ?? '',
                    element_time: '01:00',
                    year_released: item.releaseYear ?? '',
                    run_time: item.runTime ?? '',
                    cast: item.casts.map((cast, index) => ({
                        real_name: cast?.actor.fullName,
                        name: cast.characterName,
                        id: index,
                        image: {
                            url: cast.featuredImage?.image.publicUrl,
                            aspectRatio: 395/602,
                        },
                        hometown: cast?.hometown ?? null,
                        birthday: cast?.birthday ?? null,
                        biography: cast?.actor?.description,
                        facebookHandle: cast?.actor?.facebookHandle,
                        instagramHandle: cast?.actor?.instagramHandle,
                        twitterHandle: cast?.actor?.twitterHandle,
                    })),
                    seasons: item.seasons,
                }
            }),
        })
    ],
    ['movieDetails', data =>( 
      {
          MovieList: data.MovieList.items.map(item => {
              return {
                  id: item.id,
                  slug: item.slug,
                  titleCode: item.titleCode,
                  title: item.title,
                  series_synopsis: item.description,
                  year_released: (item.releaseYear) ? item.releaseYear : '',
                  featuredImage: {
                    url: item.featuredImage?.image?.publicUrl,
                    altText: item.title,
                    aspectRatio: item.featuredImage?.image?.width/item.featuredImage?.image?.height,
                  },
                  detailImage: {
                    url: item.detailImage?.image?.publicUrl,
                    altText: item.title,
                    aspectRatio: item.detailImage?.image?.width/item.detailImage?.image?.height,
                  },
                  genres: item.genre ?? '',
                  primary_color: item?.colors?.entries[0]?.value ?? '',
                  secondary_color: item?.colors?.entries[1]?.value ?? '',
                  rating: item.contentRating ?? '',
                  run_time: item.runTime ?? '',
                  element_time: '01:00',
                  cast: item.casts.map((cast, index) => ({
                      real_name: cast?.actor.fullName,
                      name: cast.characterName,
                      id: index,
                      image: {
                          url: cast.featuredImage?.image.publicUrl,
                          aspectRatio: 395/602,
                      },
                      hometown: cast?.hometown ?? null,
                      birthday: cast?.birthday ?? null,
                      biography: cast?.actor?.description,
                      facebookHandle: cast?.actor?.facebookHandle,
                      instagramHandle: cast?.actor?.instagramHandle,
                      twitterHandle: cast?.actor?.twitterHandle,
                  })),
              }
          }),
      })
    ],
    ['homepage',  data => {
      const transformedData = data.Homepage.main.map(item => {
          return carouselTransformer({carousel: item})
      })
    
      return transformedData
    }],
    ['special', data => {
      const main = data.Page?.main
      if (!main.length) return null
      if (main.length) return {
        pageType: data?.Page?.pageType,
        title: data?.Page?.displayName,
        synopsis: data?.Page?.description,
        primary_color: data?.Page?.primaryColor,
        secondary_color: data?.Page?.secondaryColor,
        heroImage: {
          url: main?.[0].mediaOption?.[0].heroImage?.[0].image?.url,
          position: [50, 50],
          dimensions: {
            width: main?.[0].mediaOption?.[0].heroImage?.[0].image?.width,
            height: main?.[0].mediaOption?.[0].heroImage?.[0].image?.height,
          },
          sources: [
              {
                media: 'min-width: 1800px',
                srcsets: [
                  `${main?.[0].mediaOption?.[0].heroImage?.[0].image?.url}`,
                ]
              },
              {
                  media: 'min-width: 1500px',
                  srcsets: [
                    `${main?.[0].mediaOption?.[0].heroImage?.[0].image?.sizes?.[0].srcSets?.[5].src} 1x`, 
                    `${main?.[0].mediaOption?.[0].heroImage?.[0].image?.url}`,
                  ]
              },
              {
                  media: 'min-width: 1200px',
                  srcsets: [
                    `${main?.[0].mediaOption?.[0].heroImage?.[0].image?.sizes?.[0].srcSets?.[4].src}`, 
                    `${main?.[0].mediaOption?.[0].heroImage?.[0].image?.sizes?.[0].srcSets?.[5].src} 2x`
                  ]
              },
              {
                  media: 'min-width: 900px',
                  srcsets: [
                    `${main?.[0].mediaOption?.[0].heroImage?.[0].image?.sizes?.[0].srcSets?.[3].src}`, 
                    `${main?.[0].mediaOption?.[0].heroImage?.[0].image?.sizes?.[0].srcSets?.[5].src} 2x`
                  ]
              },
              {
                  media: 'min-width: 600px',
                  srcsets: [
                    `${main?.[0].mediaOption?.[0].heroImage?.[0].image?.sizes?.[0].srcSets?.[2].src}`, 
                    `${main?.[0].mediaOption?.[0].heroImage?.[0].image?.sizes?.[0].srcSets?.[5].src} 1x`
                  ]
              },
              {
                  media: 'min-width: 300px',
                  srcsets: [
                    `${main?.[0].mediaOption?.[0].heroImage?.[0].image?.sizes?.[0].srcSets?.[1].src} 1x`, 
                    `${main?.[0].mediaOption?.[0].heroImage?.[0].image?.sizes?.[0].srcSets?.[3].src} 2x`
                  ]
              },
              {
                  media: 'min-width: 1px',
                  srcsets: [
                    `${main?.[0].mediaOption?.[0].heroImage?.[0].image?.sizes?.[0].srcSets?.[0].src} 1x`, 
                    `${main?.[0].mediaOption?.[0].heroImage?.[0].image?.sizes?.[0].srcSets?.[1].src} 2x`
                  ]
              }
          ]
        },
        heroMobileImage: {
          url: '',
          position: [50, 50],
          dimensions: {
            width: 0,
            height: 0,
          },
        },
        descriptionImage: {
          url: main?.[1].descriptionImage?.[0]?.image?.url,
          dimensions: {
            width: main?.[1].descriptionImage?.[0]?.image?.width,
            height: main?.[1].descriptionImage?.[0]?.image?.height,
          },
          credit: (main?.[1].descriptionImage?.[0]?.credit === "N/A" || main?.[1].descriptionImage?.[0]?.credit === "n/a") ? '' : main?.[1].descriptionImage?.[0]?.credit,
          caption: main?.[1].descriptionImage?.[0]?.caption
        },
        informationTitle: main?.[1].title,
        informationHtml: main?.[1].html,
        carousels: main.map(section => {
          if (section.slides) return carouselTransformer({carousel: section})
    
          return null
        }).filter(section => section !== null)
      }
    }],
    ['compliance', data => {
      const item = data.SectionList?.items?.[0];
      if (item) {
        return [{
          pageTitle: item.pageTitle,
          html: item.main?.find(module => module.__typename === 'CustomEmbedModule')?.html,
          iframe: item.main?.find(module => module.__typename === 'IframeModule'),
          richText: item.main?.find(module => module.__typename === 'RichTextModule')?.html,
        }];
      }
      return [];
    }],
    ['sitemap', data => {
      return data.SiteMap.items.map(item => {
        const cleanedTitle = item.title.replace(/<br\s*\/?>/gi, "");
        return {
          url: item.path,
          title: cleanedTitle
        };
      });
    }]
])

const ResourcesMap = new Map([
    ['homepage', {
        query: gql`
        {
          Homepage {
            main {
              ... on CarouselItem {
                __typename
                name
                type
                slides {
                  ... on SlideItem {
                    __typename
                    image {
                      ... on ImageView {
                        __typename
                        image {
                          publicUrl
                          width
                          height
                          sizes(names: "responsiveWidth") {
                            name
                            srcSets {
                              size
                              src
                            }
                          }
                        }
                        caption
                        alt
                      }
                    }
                    mobileImage {
                      ... on ImageView {
                        __typename
                        image {
                          publicUrl
                          width
                          height
                          sizes(names: "responsiveWidth") {
                            name
                            srcSets {
                              size
                              src
                            }
                          }
                        }
                        caption
                        alt
                      }
                    }
                    imageAlignment {
                      ... on ImageAlignment {
                        __typename
                        imageAlignmentXAxis
                        imageAlignmentYAxis
                        mobileImageAlignmentXAxis
                        mobileImageAlignmentYAxis
                      }
                    }
                    logoImage {
                      ... on ImageView {
                        __typename
                        image {
                          publicUrl
                          width
                          height
                          sizes(names: "responsiveWidth") {
                            name
                            srcSets {
                              size
                              src
                            }
                          }
                        }
                      }
                    }
                    layout
                    altTextOverride
                    omitAltText
                    body
                    headline
                    headlineAlignment
                    linkTarget
                    linkUrl
                    ctaLabel
                    linkType
                    ctaGradientEndOverride
                    ctaGradientStartOverride
                    position
                    program {
                      ... on ProgramItem {
                        __typename
                        item {
                          ... on SeriesItem {
                            __typename
                            seriesPrefix
                            slug
                            preventDetailPage
                            logoImage {
                              ... on ImageView {
                                __typename
                                image {
                                  publicUrl
                                  width
                                  height
                                  sizes(names: "responsiveWidth") {
                                    name
                                    srcSets {
                                      size
                                      src
                                    }
                                  }
                                }
                              }
                            }
                          }
                          ... on MovieItem {
                            __typename
                            titleCode
                            slug
                            preventDetailPage
                          }
                          ... on SpecialEvent {
                            __typename
                            slug
                          }
                        }
                      }
                    }
                    textColor
                    title
                    brightcoveID
                  }
                }
              }
            }
          }
        }
        
        `,
    }],
    ['showspage', {
        query: gql`
            {
                SeriesList(active: true, sorts: {field: "title", order: "asc"}) {
                    items {
                        id
                        seriesPrefix
                        slug
                        title
                        featuredImage {
                            image {
                                publicUrl
                                width
                                height
                                sizes(names: "responsiveWidth") {
                                  name
                                  srcSets {
                                    size
                                    src
                                  }
                                }
                            }
                        }
                        preventDetailPage
                    }
                }
            }
        `,
        queryVars: [],
    }],
    ['activeShows', {
      query: gql`
            {
                SeriesList(active: true, sorts: {field: "seriesPrefix", order: "asc"}) {
                    items {
                        id
                        seriesPrefix
                    }
                }
            }
        `,
        queryVars: [],
    }],
    ['showDetails', {
        query: gql`
          query GetShowDetail($id: String!) {
            SeriesList(active: true, omitPreventedDetailPages: true, match: {predicate: $id}, sorts: {field: "title", order: "asc"}) {
              items {
                id
                slug
                seriesPrefix,
                title
                colors {
                  entries {
                    key
                    value
                  }
                }
                detailImage {
                  image {
                    publicUrl
                    width
                    height
                  }
                }
                featuredImage {
                  image(size: "responsiveWidth") {
                    publicUrl
                    srcSets {
                      size
                      src
                    }
                    height
                    width
                  }
                }
                description
                casts {
                  actor {
                    fullName
                    featuredImage {
                      image {
                        publicUrl
                      }
                    }
                    description
                    facebookHandle
                    instagramHandle
                    twitterHandle
                  }
                  characterName
                  featuredImage {
                    image {
                      publicUrl
                    }
                    narrowImage {
                      publicUrl
                    }
                  }
                }
                contentRating
                genre
              }
            }
          }
        `
    }],
    ['movieDetails', {
      query: gql`
        query GetMovieDetail($id: String!) {
          MovieList(active: true, omitPreventedDetailPages: true, match: {predicate: $id}, sorts: {field: "title", order: "asc"}) {
            items {
              id
              titleCode
              slug
              title
              colors {
                entries {
                  key
                  value
                }
              }
              detailImage {
                image {
                  publicUrl
                  width
                  height
                }
              }
              featuredImage {
                image(size: "responsiveWidth") {
                  publicUrl
                  srcSets {
                    size
                    src
                  }
                  height
                  width
                }
              }
              description
              casts {
                actor {
                  fullName
                  featuredImage {
                    image {
                      publicUrl
                    }
                  }
                  description
                  facebookHandle
                  instagramHandle
                  twitterHandle
                }
                characterName
                featuredImage {
                  image {
                    publicUrl
                  }
                  narrowImage {
                    publicUrl
                  }
                }
              }
              contentRating
              genre
              releaseYear
            }
          }
        }
      `
  }],
    ['showFeaturedImage', {
      query: gql`
        query GetSeriesFeaturedImage($id: String!) {
          SeriesList(active: true, match: {predicate: $id}) {
            items {
              slug
              featuredImage {
                image {
                  width
                  height
                  publicUrl
                  sizes(names: "responsiveWidth") {
                    name
                    srcSets {
                      size
                      src
                    }
                  }
                }
              }
            }
          }
        }
    `
    }],
    ['movieFeaturedImage', {
      query: gql`
        query GetMovieFeaturedImage($id: String!) {
          MovieList(active: true, match: {predicate: $id}) {
            items {
              slug
              featuredImage {
                image {
                  width
                  height
                  publicUrl
                  sizes(names: "responsiveWidth") {
                    name
                    srcSets {
                      size
                      src
                    }
                  }
                }
              }
            }
          }
        }
    `
    }],
    ['episodes', {
        query: gql`
        query GetSeriesEpisodes($slug: String!) {
            SeriesList(active: true, predicate: $slug) {
              items {
                seasons {
                  id
                  seasonNumber
                  episodes {
                    title
                    description
                    synopsis
                    episodeNumber
                    elementLength
                    featuredImage {
                      image {
                        publicUrl
                        sizes(names: "responsiveWidth") {
                          srcSets {
                            size
                            src
                          }
                        }
                      }
                    }
                    brightcoveID
                  }
                }
              }
            }
          }
        
        `
    }],
    ['onnow', {
      query: gql`
      query GetSeriesOnNow($id: String!) {
        SeriesList(active: true, match: {predicate: $id}) {
          items {
            id
            slug
            seriesPrefix
            title
            logoImage {
              image {
                width
                height
                publicUrl
                sizes(names: "responsiveWidth") {
                  name
                  srcSets {
                    size
                    src
                  }
                }
              }
            }
            nowPlayingImage {
              image {
                publicUrl
                width
                height
                sizes(names: "responsiveWidth") {
                  name
                  srcSets {
                    size
                    src
                  }
                }
              }
            }
            colors {
              entries {
                key
                value
              }
            }
          }
        }
      }  
      `
    }],
    ['onnowinactive', {
      query: gql`
      query GetSeriesOnNow($id: String!) {
        SeriesList(active: false, match: {predicate: $id}) {
          items {
            id
            slug
            seriesPrefix
            title
            logoImage {
              image {
                width
                height
                publicUrl
                sizes(names: "responsiveWidth") {
                  name
                  srcSets {
                    size
                    src
                  }
                }
              }
            }
            nowPlayingImage {
              image {
                publicUrl
                width
                height
                sizes(names: "responsiveWidth") {
                  name
                  srcSets {
                    size
                    src
                  }
                }
              }
            }
            colors {
              entries {
                key
                value
              }
            }
          }
        }
      }  
      `
    }],
    ['special', {
      query: gql`
      query GetSpecial($path: String!) {
        Page(path: $path) {
          pageType
          description
          displayName
          primaryColor
          secondaryColor
          main {
            ... on MediaModule {
              mediaOption {
                ... on ImageMediaOption {
                  heroImage: image {
                    ... on ImageView {
                      image {
                        url: publicUrl
                        width
                        height
                        sizes(names: "responsiveWidth") {
                          name
                          srcSets {
                            size
                            src
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            ... on ImageWithTextItem {
              title
              layout
              html
              descriptionImage: image {
                ... on ImageView {
                  image {
                    url: publicUrl
                    width
                    height
                    sizes(names: "responsiveWidth") {
                      name
                      srcSets {
                        size
                        src
                      }
                    }
                  }
                  credit
                  caption
                }
              }
            }
            ... on CarouselItem {
              name
              type
              slides {
                ... on SlideItem {
                  title
                  brightcoveID
                  layout
                  body
                  image {
                    ... on ImageView {
                      __typename
                      image {
                        publicUrl
                        width
                        height
                        sizes(names: "responsiveWidth") {
                          name
                          srcSets {
                            size
                            src
                          }
                        }
                      }
                    }
                  }
                  ctaLabel
                  linkTarget
                  linkType
                  linkUrl
                  mobileImage {
                    ... on ImageView {
                      __typename
                      image {
                        publicUrl
                        width
                        height
                      }
                    }
                  }
                  position
                  #imageAlignmentXAxis
                  #imageAlignmentYAxis
                  #mobileImageAlignmentXAxis
                  #mobileImageAlignmentYAxis
                  altTextOverride
                  headline
                }
              }
            }
          }
        }
      }      
      `,
    }],
    ['sweeps', {
      query: gql`
        query GetSpecial($slug: String!) {
          Page(path: $slug) {
            pageType
            displayName
            backgroundImage {
              image {
                publicUrl
              }
            }
            primaryColor #gradient start
            secondaryColor #gradient end
            main {
              ... on MediaModule {
                mediaOption {
                  ... on ImageMediaOption {
                    image {
                      ... on ImageView {
                        image {
                          publicUrl #header image
                        }
                      }
                    }
                  }
                }
              }
              ... on HtmlModule {
                rawHtml #html contents
              }
              ... on CustomEmbedModule {
                html #css override
              }
            }
          }
        }
      `
    }],
    ['compliance', {
      query: gql`
          query GetCompliancePage($predicate: String!, $includeGlobal: Boolean!) {
              SectionList(predicate: $predicate, includeGlobal: $includeGlobal) {
                  items {
                      pageTitle
                      main {
                          __typename
                          ... on CustomEmbedModule {
                              html
                          }
                          ... on IframeModule {
                            title
                            url
                          }
                          ... on RichTextModule {
                            __typename
                            html
                          }
                      }
                  }
              }
          }
      `,
    }],
    ['page', {
      query: gql`
        query GetPageType($path: String!) {
          Page(path: $path) {
            pageType
          }
        }
      `
    }],
    ['sitemap', {
      query: gql`
        query GetSitemap {
          SiteMap {
            items {
              path
              title
            }
          }
        }
      `
    }]
])

const headers = (process.env.REACT_APP_ENV === 'production') 
? {
    'X-Site': SITE_ID,
    'X-API-Key': BS_API_TOKEN,
  } 
: {
    'X-Site': SITE_ID,
    'X-API-Key': BS_API_TOKEN,
    Authorization: `Basic ${process.env.REACT_APP_BS_AUTH}`,
  } 

const getResource = async ({ queryKey }) => {
    const { resourceName = '', resourceId = null, queryVars = {}, } = queryKey[1]

    const results = await request({
        url: ENDPOINT, 
        document: ResourcesMap.get(resourceName).query,
        variables: queryVars,
        requestHeaders: headers
    }).catch(err => {
      console.log(err)
      return null
    })

    const transformedResults = (Transformers.get(resourceName)) ? Transformers.get(resourceName)(results) : results
    
    return transformedResults
}
const getResourceList = async ({ queryKey }) => {
    const { resourceName = '', queryVars = {}, } = queryKey[1]

    const results = await request({
        url: ENDPOINT, 
        document: ResourcesMap.get(resourceName).query,
        variables: queryVars,
        requestHeaders: headers
    }).catch(err => {
      console.log(err)
      return null
    })

    const transformedResults = (Transformers.get(resourceName)) ? Transformers.get(resourceName)(results) : results
    
    return transformedResults
}

export {
    ResourcesMap,
    getResource,
    getResourceList,
}